.rpv-core__viewer .pdf-toolbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #eeeeee;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  padding: 8px;
}

.pdf-toolbar-container .zoom-div {
  display: flex;
  align-items: center;
}

@media (max-width: 375px) {
  .rpv-core__viewer .pdf-toolbar-container {
    flex-direction: column;
    justify-content: center;
  }

  .pdf-toolbar-container .zoom-div {
    margin-bottom: 10px;
  }
}

.pdf-dropdown-container-div div:hover {
  background-color: rgba(0, 124, 22, 0.2);
}

.h6 {
  font-size: 18px;
  font-weight: 600;
  color: #6a6c6f;
  font-weight: 600;
  padding: 10px 4px;
  transition: all 0.3s;
  border: 1px solid transparent;
}

.media-heading-button-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  padding: 10px;
  background-color: #fff;
}

@media (max-width: 768px) {
  .media-heading-button-container {
    flex-direction: column;
    justify-content: center;
  }
}

.add-and-filter-btns-div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
}

.action-btns-div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

@media (max-width: 768px) {
  .action-btns-div {
    flex-direction: column;
  }
}

.action-buttons {
  width: fit-content;
  margin: 0 5px;
  padding: 8px 10px;
  border-radius: 2px;
  outline: none;
  background-color: #007c16;
  color: #fff;
  border: none;
}

@media (max-width: 768px) {
  .action-buttons {
    margin: 5px 0;
  }
}

.submit-and-cancel-div {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 15px 0;
  border-top: 1px solid #eaeaea;
  margin-top: 15px;
}

.submit-and-cancel-div .cancel {
  min-width: 110px;
  padding: 8px;
  border-radius: 2px;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
  border: 2px solid #6c757d;
  background-color: #6c757d;
  color: #fff;
}

.submit-and-cancel-div .submit {
  min-width: 110px;
  padding: 8px;
  border-radius: 2px;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid;
  color: #fff;
}

.submit.addModal {
  border-color: #007c16;
  background-color: #007c16;
}
