.link-receipt-modal {
  width: 50%;
  height: 70dvh;
  background-color: #fff;
  background-image: url("../images/bg3.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 15px;
  overflow-y: hidden;
  position: relative;
}

.link-receipt-modal .link-receipt-box {
  width: 100%;
  height: 90%;
  background-color: #fff;
  margin: 0;
  padding: 20px;
  overflow-y: auto;
  position: relative;
  display: flex;
  flex-direction: row;
}

.linking-form {
  flex: 1;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
}

.example-img {
  flex: 1;
  height: 100%;
  margin-left: 10px;
}

.example-img img {
  width: 100%;
  height: 100%;
}

@media (max-width: 1024px) {
  .link-receipt-modal {
    width: 90%;
    height: auto;
  }

  .link-receipt-modal .link-receipt-box {
    padding: 30px;
  }
}

@media (max-width: 768px) {
  .link-receipt-modal {
    background-image: none;
  }
}

@media (max-width: 540px) {
  .link-receipt-modal {
    width: 100%;
    height: 100dvh;
  }

  .link-receipt-modal .link-receipt-box {
    flex-direction: column;
    height: auto;
  }

  .linking-form {
    width: 100%;
    margin: 10px 0;
  }

  .example-img {
    width: 100%;
    margin-left: 0;
    margin-top: 10px;
  }
}

@media (max-height: 400px) {
  .link-receipt-modal {
    background-image: none;
    width: 100%;
    height: 100dvh;
  }

  .link-receipt-modal .link-receipt-box {
    flex-direction: column;
    height: auto;
    padding: 30px;
  }

  .linking-form {
    width: 100%;
    margin: 10px 0;
  }

  .example-img {
    width: 100%;
    margin-left: 0;
    margin-top: 10px;
  }
}

@media (max-width: 450px) {
  .link-receipt-modal .link-receipt-box {
    padding: 20px;
  }
}

@media (max-width: 360px) {
  .link-receipt-modal .link-receipt-box {
    padding: 10px;
  }
}

.link-receipt-modal-header {
  border-bottom: 1px solid #e4e4e4;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 15;
  background: #fff;
  padding: 5px;
  text-align: center;
}

.link-cross-icon {
  font-weight: bold;
  cursor: pointer;
  border: none;
  outline: none;
  background-color: transparent;
  margin-right: 15px;
}

.modal-header-heading {
  margin-left: 15px;
  margin-top: 5px;
}

.link-attach-btn {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  background-color: #007c16 !important;
  color: #fff !important;
  margin-left: 5px;
}

.link-loading-screen {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(255 255 255 / 50%);
  display: flex;
  justify-content: center;
  align-items: center;
}
