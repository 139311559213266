/* profile */
.profile-div {
  padding: 30px;
  background-color: #ffffff;
}

.options-div .profile-options {
  margin-right: 10px;
  background-color: transparent !important;
  color: #007c16 !important;
}

.profile-options.active {
  background-color: #007c16 !important;
  color: #fff !important;
}

@media (max-width: 540px) {
  .options-div .profile-options {
    margin-bottom: 10px;
    width: 100%;
  }
}

.h5-in-profile {
  margin-bottom: 15px;
}

.register-btn-div.profile {
  justify-content: flex-end;
}

.update-profile-img-container {
  min-height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.update-profile-img-container .profile-circle {
  width: 250px;
  height: 250px;
  background-color: #007c16;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 8px 16px rgba(0, 0, 0, 0.1),
    0 12px 24px rgba(0, 0, 0, 0.1), 0 16px 32px rgba(0, 0, 0, 0.1);
}

.profile-circle img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
  overflow-clip-margin: unset;
}

.profile-circle .upload-or-edit-icon-cirle {
  position: absolute;
  bottom: 20px;
  right: 20px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #f8f8f8;
  border: 1px solid #007c16;
  display: flex;
  justify-content: center;
  align-items: center;
}

.input-file {
  display: none;
}

.profile-circle .upload-or-edit-icon-cirle label {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.profile-circle h3 {
  color: #fff;
  font-size: 36px;
  font-weight: 600;
}

.delete-btn {
  background-color: #dc3546 !important;
  min-width: 150px;
  margin-top: 50px;
}

.save-btn {
  background-color: #007c16 !important;
  width: 100%;
  margin: 15px 0;
}

.dp-container {
  width: 250px;
  height: 250px;
  border-radius: 50%;
  background-color: #f7f8fa;
  border: 1px solid #007c16;
  overflow: hidden;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dp-container img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
  overflow-clip-margin: unset;
}
