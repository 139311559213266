/* signup */
.background {
  width: 100%;
  min-height: 100dvh;
  background-image: url("../images/bg3.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.box {
  width: 60%;
  height: 100%;
  background-color: #fff;
  border-radius: 8px;
  padding: 10px 20px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.box::-webkit-scrollbar {
  display: none;
}

.simplebar-scrollbar::before {
  background-color: #9899ac !important;
}

@media (max-width: 1024px) {
  .box {
    width: 100%;
    height: auto;
  }
}

.loading-screen {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(255 255 255 / 50%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.auth-logo {
  width: 175px;
  height: auto;
  margin-left: -40px;
}

.auth-logo img {
  width: 100%;
  height: 100%;
}

.box .auth-form .auth-heading {
  margin-bottom: 15px;
}

.auth-form .custom-row {
  width: 100%;
  display: flex;
}

.auth-form .custom-row .custom-col {
  margin-right: 10px;
  width: 100%;
  margin-bottom: 15px;
}

.register-btn-div {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  align-items: center;
}

.go-login-text {
  font-size: 14px;
  color: #8f8f8f;
  text-align: center;
}

.green-link {
  color: #007c16 !important;
}

.register-btn-div .register-btn {
  background-color: #007c16 !important;
  letter-spacing: 0.09em;
}

.error-msg {
  background-color: #f8d7da;
  font-size: 12px;
  color: #721c24;
  border-color: #f5c6cb;
  padding: 0.75rem 1.25rem;
  margin-top: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  width: fit-content;
}

@media (max-width: 540px) {
  .auth-form .custom-row {
    flex-direction: column;
  }

  .auth-form .custom-row .custom-col {
    margin-right: 0;
    margin-bottom: 5px;
  }

  .register-btn-div {
    flex-direction: column-reverse;
    justify-content: center;
  }

  .register-btn-div .register-btn {
    width: 100%;
    margin: 7px 0;
  }
}

@media (max-width: 375px) {
  .background {
    padding: 0;
    background-image: none;
    background-color: #fff;
  }
}

/* login */
.box.login {
  width: 500px;
  height: auto;
  padding: 30px;
}

.user-message {
  background-color: #d4edda;
  font-size: 12px;
  color: #155724;
  border-color: #c3e6cb;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  width: fit-content;
}

.go-signup-text {
  font-size: 14px;
  color: #8f8f8f;
  margin-top: 15px;
}

.auth-action-btns-div {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 15px;
  align-items: center;
}

@media (max-width: 540px) {
  .auth-action-btns-div {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }
}

.login-btn {
  background-color: #007c16 !important;
  letter-spacing: 0.09em;
}

@media (max-width: 540px) {
  .login-btn {
    width: 100%;
    margin: 7px 0;
  }
}

.forget-password {
  color: #8f8f8f;
  background-color: transparent;
  border: none;
  outline: none;
}

.auth-form input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #fff inset !important;
}

.edit-media-inputs {
  min-height: auto;
  padding-top: 0.33em;
  padding-bottom: 0.33em;
  padding-left: 0.75em;
  padding-right: 0.75em;
  border: 1px solid #bdbdbd;
  background: transparent;
  transition: all 0.2s linear;
  display: block;
  width: 100%;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.6;
  color: #4f4f4f;
  appearance: none;
  border-radius: 0.25rem;
}

.edit-media-inputs:focus,
.edit-media-inputs:active {
  border-color: #007c16;
  outline-color: #007c16;
}

/* custom radio btns */
.custom-btn-group {
  box-shadow: none !important;
  margin-bottom: 25px;
  margin-top: -5px;
}

.btn-transparent {
  background-color: #fff;
  color: #007c16;
}

.btn-check:checked + .btn-theme-green,
.btn-check:active + .btn-theme-green,
.btn-theme-green:active,
.btn-theme-green.active,
.show > .btn-theme-green.dropdown-toggle {
  color: #fff;
  background-color: #007c16;
}
