.tax-receipt-modal {
  width: 70%;
  height: 90dvh;
  background-color: #fff;
  background-image: url("../images/bg3.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 15px;
  overflow-y: auto;
}

.tax-receipt-modal .tax-receipt-box {
  width: 55%;
  height: 100%;
  background-color: #fff;
  margin-left: 45%;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  position: relative;
}

.tax-receipt-modal .tax-receipt-box.step-2 {
  width: 100%;
  margin-left: 0;
}

@media (max-width: 1024px) {
  .tax-receipt-modal {
    width: 90%;
    height: auto;
  }

  .tax-receipt-modal .tax-receipt-box {
    padding: 30px;
  }
}

@media (max-width: 768px) {
  .tax-receipt-modal {
    background-image: none;
  }

  .tax-receipt-modal .tax-receipt-box {
    width: 100%;
    margin: 0;
  }
}

@media (max-width: 540px) {
  .tax-receipt-modal {
    width: 100%;
    height: 100dvh;
  }
}

@media (max-height: 400px) {
  .tax-receipt-modal {
    background-image: none;
    width: 100%;
    height: 100dvh;
  }

  .tax-receipt-modal .tax-receipt-box {
    width: 100%;
    margin: 0;
    padding: 30px;
  }
}

@media (max-width: 450px) {
  .tax-receipt-modal .tax-receipt-box {
    padding: 20px;
  }
}

@media (max-width: 360px) {
  .tax-receipt-modal .tax-receipt-box {
    padding: 10px;
  }
}

.tax-receipt-modal-header {
  border-bottom: 1px solid #e4e4e4;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 15;
  background: #fff;
  padding: 15px;
  text-align: center;
}

.tax-cross-icon {
  font-weight: bold;
  cursor: pointer;
  border: none;
  outline: none;
  background-color: transparent;
  margin-bottom: 5px;
}

.tax-receipt-modal-body {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
}

@media (max-width: 540px) {
  .tax-receipt-modal-body {
    height: auto;
  }
}

.tax-receipt-modal-body.screen-2 {
  flex-direction: row;
}

@media (max-width: 540px) {
  .tax-receipt-modal-body.screen-2 {
    flex-direction: column;
  }
}

@media (max-width: 540px) {
  .tax-receipt-modal-body {
    justify-content: flex-start;
  }
}

.screen-left {
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.screen-right {
  flex: 1;
  height: 100%;
  position: relative;
}

@media (max-width: 540px) {
  .screen-left,
  .screen-right {
    width: 95%;
  }

  .screen-right {
    border-left: none;
    border-top: 1px solid #007c16;
    margin-top: 20px;
  }

  .screen-right.padding {
    padding: 100px;
  }
}

.selects {
  width: 350px;
}

@media (max-width: 1024px) {
  .selects {
    margin-bottom: 25px;
  }
}

@media (max-width: 540px) {
  .selects {
    width: 95%;
    margin-bottom: 0;
  }
}

.select-msg {
  color: #007c16;
  letter-spacing: 0.09em;
  text-align: center;
  margin: 30px 0;
}

/* screen-2 */
.select-all-box {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 5px 5px 4px #e4e4e4;
  padding: 20px;
  letter-spacing: 0.09em;
  border-left: 1px solid #007c16;
}

.select-all-box .select-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.select-container-h5 {
  color: #007c16;
  font-size: 16px;
  margin-left: 10px;
  margin-top: 8px;
  text-align: center;
}

.form-check-input[type="checkbox"]:checked {
  background-color: #007c16 !important;
}

.form-check-input:checked {
  border-color: #007c16 !important;
}

.transactions-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  border-left: 1px solid #007c16;
}

@media (max-width: 540px) {
  .select-all-box,
  .transactions-container {
    border: none;
  }
}

.transactions-container-row {
  border-bottom: 1px solid #e4e4e4;
  padding: 10px;
  letter-spacing: 0.09em;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.row-left,
.row-right {
  display: flex;
}

.row-left h5 {
  font-size: 14px;
  margin-top: 4px;
  margin-left: 5px;
}

.row-left h6 {
  font-size: 12px;
  color: rgb(143 143 143);
  margin-left: 5px;
}

.view-download-div {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 10px;
  border-top: 1px solid #e4e4e4;
}

.view-download-btn {
  background-color: #007c16 !important;
  color: #fff !important;
  letter-spacing: 0.09em;
}
