.receipt-modal {
  width: 450px;
  height: 100dvh;
  overflow-y: auto;
  position: absolute;
  top: 0;
  right: 0;
  background-color: #fff;
  padding: 12px;
}

@media (max-width: 540px) {
  .receipt-modal {
    width: 100%;
  }
}

@media print {
  .receipt-modal {
    width: 100%;
  }
}

.receipt-modal-header {
  border-bottom: 1px solid #e4e4e4;
}

.cross-icon.donations {
  top: 13px;
  right: 12px;
}

.receipt-modal-body {
  margin-top: 20px;
}

.receipt-info {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 30px 0;
  padding: 15px 0;
  border-bottom: 1px solid #e4e4e4;
}

.receipt-campaign {
  color: #007c16;
}

.receipt-other-info {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 10px 0;
  padding: 5px 0;
  border-bottom: 1px solid #e4e4e4;
}

.receipt-other-info .receipt-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 7px 0;
}

.receipt-key {
  color: #8f8f8f;
  font-weight: 600;
  font-size: 13px;
}

.receipt-value {
  font-weight: 600;
  font-size: 13px;
}

.receipt-card-info {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 10px 0;
  padding: 5px 0;
  border-bottom: 1px solid #e4e4e4;
}

.receipt-card-img-div {
  width: 45px;
  height: auto;
  margin-right: 15px;
}

.receipt-card-img-div .card-img {
  width: 100%;
  height: 100%;
}

.goto-info,
.goto-info:hover {
  margin: 10px 0;
  padding: 5px 0;
  border-bottom: 1px solid #e4e4e4;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
  color: #007c16;
}

.download-and-close-btn-div {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
  padding: 5px 0;
}

.view-pdf-btn {
  margin-right: 10px;
  background-color: #007c16 !important;
  letter-spacing: 0.09em;
}

/* receipt */
.receipt-box {
  width: 100%;
  display: flex;
  flex-direction: column;
}

@media print {
  .receipt-modal-header {
    display: none;
  }
}

.receipt-box .actual-receipt {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 7px;
}

.actual-receipt .receipt-organization-logo {
  width: 60px;
  height: auto;
}

.receipt-organization-logo img {
  width: 100%;
  height: 100%;
}

.actual-receipt h5 {
  font-weight: 600;
  margin-top: 20px;
  font-size: 13px;
}

.actual-receipt h6 {
  font-size: 13px;
}

.actual-receipt .phone-and-website {
  margin-top: 0;
  font-size: 13px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.phone-and-website p {
  margin: 0;
}

.actual-receipt p a,
.actual-receipt p a:hover {
  color: #007c16;
}

.colored-row {
  background-color: #007c16;
  color: #fff;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  padding: 10px;
}

body {
  -webkit-print-color-adjust: exact !important;
  /* stylelint-disable */
  print-color-adjust: exact !important; /* stylelint-disable property-no-unknown */
  /* stylelint-enable */
}

@media print {
  .colored-row {
    background-color: #007c16 !important;
    color: #fff;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    padding: 10px;
  }
}

.colored-row.first {
  margin-top: 20px;
}

.colored-row span {
  font-weight: bold;
  font-size: 16px;
  display: flex;
  align-items: center;
}

.data-row {
  background-color: #fff;
  color: #3a3a3a;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-top: 3px;
  padding: 10px;
}

.font-weight {
  font-weight: 600;
  color: #8f8f8f;
}

@media print {
  @page {
    size: auto;
    margin: 0;
  }
}

@media print {
  .data-row {
    background-color: #fff;
    color: #3a3a3a;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-top: 3px;
    padding: 10px;
  }

  .font-weight {
    font-weight: 600;
    color: #8f8f8f;
  }
}

.receipt-actions-div {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 7px;
}

@media (max-width: 450px) {
  .receipt-actions-div {
    flex-direction: column;
    justify-content: center;
  }
}

@media print {
  .receipt-actions-div {
    display: none;
  }
}

@media (max-width: 450px) {
  .actions-left {
    width: 100%;
    margin-bottom: 10px;
  }
}

.receipt-modal-download-button {
  border: 1px solid #007c16;
  background-color: transparent !important;
  color: #007c16 !important;
  font-weight: bold;
  letter-spacing: 0.09em;
}

@media (max-width: 450px) {
  .actions-right {
    width: 100%;
  }
}

.receipt-modal-print-button {
  background-color: #007c16 !important;
  font-weight: bold;
  letter-spacing: 0.09em;
}

@media (max-width: 768px) {
  .receipt-modal-print-button {
    display: none !important;
  }

  .receipt-modal-download-button {
    width: 100%;
    background-color: #007c16 !important;
    color: #fff !important;
  }
}
