.main-heading {
  margin: 25px;
  padding-bottom: 15px;
  font-size: 24px;
  border-bottom: 1px solid #e4e4e4;
}

/* main container */
.stats-recent-donations-box {
  display: flex;
  align-items: flex-start;
  margin: 25px;
  height: 650px;
}

/* leftside */
.stats-last-top-box {
  width: 50%;
  display: flex;
  flex-direction: column;
  margin-right: 10px;
  height: 100%;
}

/* rightside */
.recent-box {
  flex: 1;
  height: 100%;
  background-color: #fff;
  border-radius: 12px;
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  box-shadow: 0 1px 15px rgb(0 0 0 / 4%), 0 1px 6px rgb(0 0 0 / 4%);
}

@media (max-width: 1024px) {
  .stats-recent-donations-box {
    height: auto;
    flex-direction: column;
  }

  .stats-last-top-box {
    width: 100%;
    margin-right: 0;
    margin-bottom: 10px;
  }

  .recent-box {
    width: 100%;
    margin-left: 0;
    margin-top: 10px;
  }
}

.recent-box::-webkit-scrollbar {
  width: 10px;
}

.recent-box::-webkit-scrollbar-track {
  background-color: transparent;
}

.recent-box::-webkit-scrollbar-thumb {
  background-color: #ccccd6;
  border-radius: 100px;
  visibility: hidden;
}

.recent-box::-webkit-scrollbar-thumb:hover {
  visibility: visible;
}

.stats-last-top-box .numbers-data {
  width: 100%;
  height: auto;
  display: flex;
  margin-bottom: 10px;
  z-index: 0;
}

.numbers-card {
  min-width: auto;
  padding: 20px;
  text-align: center;
  height: 170px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  cursor: pointer;
  margin-bottom: 10px;
  box-shadow: 0 1px 15px rgb(0 0 0 / 4%), 0 1px 6px rgb(0 0 0 / 4%);
}

.numbers-card .numbers-card-icon {
  color: #007c16;
}

.numbers-card h6 {
  text-align: center;
  margin-top: 16px;
  font-weight: bold;
  color: rgb(143 143 143);
  font-size: 14px;
}

.numbers-card span {
  color: #007c16;
  margin-top: 8px;
  font-size: 18px;
  letter-spacing: 0.09em;
}

.stats-last-top-box .last-top-box {
  width: 100%;
  height: 460px;
  background-color: #fff;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  box-shadow: 0 1px 15px rgb(0 0 0 / 4%), 0 1px 6px rgb(0 0 0 / 4%);
}

@media (max-width: 1024px) {
  .stats-last-top-box .last-top-box {
    height: auto;
  }
}

.chart-container {
  width: 100%;
  height: 300px;
  margin-top: 20px;
  overflow-y: auto;
}

.chart-container.doughnut {
  height: 350px;
}

@media (max-width: 450px) {
  .chart-container {
    height: 250px;
  }

  .chart-container.doughnut {
    height: 300px;
  }
}

@media (max-width: 376px) {
  .chart-container {
    height: 200px;
  }

  .chart-container.doughnut {
    height: 250px;
  }
}

.recent-donations-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.recent-donations-header h3 {
  margin-top: 7px;
  font-size: 16px;
  letter-spacing: 0.09em;
  color: #3a3a3a;
  margin-left: 10px;
}

.recent-donations-header button {
  background: transparent;
  outline: none;
  border: none;
  color: #007c16;
  letter-spacing: 0.09em;
  font-size: 16px;
  margin-right: 10px;
}

@media (max-width: 376px) {
  .recent-donations-header {
    flex-direction: column;
    justify-content: center;
  }

  .recent-donations-header h3 {
    margin-left: 0;
  }

  .recent-donations-header button {
    margin-right: 0;
  }
}

/* recent donations box */
.recent-donations-box {
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.recent-donations-loader {
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.recent-donations-box .recent-donations-card {
  margin-bottom: 10px;
  border-bottom: 1px solid #e4e4e4;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.campaign-img-and-details-div {
  display: flex;
  align-items: center;
}

.recent-donations-card .recent-campaign-img {
  width: 70px;
  height: auto;
  margin-right: 30px;
}

.recent-campaign-img img {
  width: 100%;
  height: 100%;
}

.recent-details-box {
  width: 170px;
  overflow: hidden;
}

.recent-donations-card h5 {
  font-size: 12px;
  color: rgb(143 143 143);
  letter-spacing: 0.09em;
  margin-bottom: 5px;
}

.recent-donations-card .transaction-date {
  color: #007c16;
}

.recent-card-img {
  width: 50px;
  height: auto;
  margin-right: 5px;
}

@media (max-width: 376px) {
  .recent-donations-box .recent-donations-card {
    flex-direction: column;
    justify-content: center;
  }

  .campaign-img-and-details-div {
    flex-direction: column;
    justify-content: center;
  }

  .recent-donations-card .recent-campaign-img {
    margin-right: 0;
    margin-bottom: 30px;
  }

  .recent-donations-card h5 {
    text-align: center;
  }

  .recent-card-img {
    margin-right: 0;
    margin-bottom: 5px;
  }
}

.recent-card-img img {
  width: 100%;
  height: 100%;
}

/* taxable and non taxable */
.tax-and-nontax-container {
  margin: 25px;
  height: 420px;
  display: flex;
  align-items: flex-start;
}

.taxable-container,
.nontaxable-container {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 1px 15px rgb(0 0 0 / 4%), 0 1px 6px rgb(0 0 0 / 4%);
  height: 100%;
}

.taxable-container {
  margin-right: 10px;
  width: 50%;
}

.nontaxable-container {
  flex: 1;
  margin-left: 10px;
}

@media (max-width: 1024px) {
  .tax-and-nontax-container {
    height: auto;
    flex-direction: column;
  }

  .taxable-container {
    margin-bottom: 10px;
    width: 100%;
    margin-right: 0;
  }

  .nontaxable-container {
    width: 100%;
    margin-left: 0;
    margin-top: 10px;
  }
}

.taxable-select {
  background-color: #007c16 !important;
  color: #fff !important;
  width: 100px !important;
  border-color: #007c16 !important;
  border-radius: 20px !important;
}

.taxable-select:focus {
  border-color: #007c16 !important;
  box-shadow: 0 0 5px rgba(0, 124, 22, 0.2) !important;
}

.organization-select-div {
  text-align: right;
}

.organization-select-label {
  margin-right: 10px;
}

.organization-select {
  width: 300px !important;
  background-color: transparent !important;
  border: 1px solid #007c16 !important;
  color: #007c16 !important;
  border-radius: 20px !important;
  margin-right: 0 !important;
  margin-bottom: 10px !important;
  box-shadow: none !important;
}

.organization-select:focus {
  border-color: #007c16 !important;
  box-shadow: 0 0 5px rgba(0, 124, 22, 0.2) !important;
}

@media (max-width: 376px) {
  .taxable-select {
    width: 100% !important;
  }

  .organization-select-label {
    margin-right: 0;
  }

  .organization-select-div {
    text-align: center;
  }

  .organization-select {
    width: 100% !important;
    margin-right: 0 !important;
  }
}

/* by campaigns */
.bycampaigns-container {
  margin: 25px;
  display: flex;
  align-items: flex-start;
  height: 550px;
}

.by-campaigns-box {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 1px 15px rgb(0 0 0 / 4%), 0 1px 6px rgb(0 0 0 / 4%);
  height: 100%;
  margin-right: 10px;
  width: 50%;
}

@media (max-width: 1024px) {
  .bycampaigns-container {
    height: auto;
    flex-direction: column;
  }

  .by-campaigns-box {
    margin-bottom: 10px;
    width: 100%;
    margin-right: 0;
  }
}

.custom-menu {
  width: 300px !important;
  height: 350px !important;
  overflow-x: auto !important;
  overflow-y: auto !important;
  scrollbar-width: none !important;
  -ms-overflow-style: none !important;
}

.custom-menu::-webkit-scrollbar {
  display: none;
}

.simplebar-scrollbar::before {
  background-color: #9899ac !important;
}

@media (max-width: 360px) {
  .custom-menu {
    height: 280px !important;
  }
}
