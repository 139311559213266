/* step 0 */
.embed-charity-details-container {
  width: 90%;
  margin: 10px auto;
  border-radius: 6px;
  background-color: #fff;
  box-shadow: 2px 2px 3px #e4e4e4;
  position: relative;
}

.embed-charity-details-header {
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fbfbfb;
  padding: 10px;
  color: #282a2c;
}

.arrow-left-span {
  position: absolute;
  top: 15px;
  left: 8px;
  cursor: pointer;
}

.choose-amount {
  margin-top: 10px;
}

/* step 1 */
.embed-logo-container {
  background-color: rgba(0, 124, 22, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px;
}

.embed-logo-container .embed-campaign-logo {
  width: 100px;
  height: auto;
}

.embed-campaign-logo img {
  width: 100%;
  height: 100%;
}

.embed-logo-container .embed-charity-organization-name {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 8px;
}

.embed-charity-organization-name .embed-charity-name {
  font-size: 20px;
  font-weight: 600;
  color: #4b4b4b;
}

.embed-charity-organization-name .embed-organization-name {
  color: #9b9b9b;
  font-size: 20px;
}

.embed-charity-msg {
  color: #6f6f6f;
  font-size: 16px;
  width: 100%;
  text-align: center;
  margin: 16px auto;
  padding: 16px;
}

.input-and-minimum-amount-msg-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 16px auto;
}

.big-input-div {
  width: 100%;
  max-width: 300px;
  display: flex;
  align-items: center;
  border: 2px solid #007c16;
  border-radius: 4px;
  padding: 8px;
}

.minimum-amount-msg.embed {
  width: 100%;
  max-width: 300px;
  margin-top: 6px;
  font-size: 14px;
}

.minimum-amount-msg.embed.red {
  color: #dc3545;
}

.big-input-div .dollar {
  width: 25%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  font-size: 28px;
  font-weight: 600;
  padding-left: 8px;
  color: #282a2c;
}

.big-input-div input {
  width: 75%;
  font-size: 42px;
  text-indent: 2px;
  padding: 8px;
  border: none;
  outline: none;
  border-radius: 4px;
  background-color: transparent;
}

.amounts-div {
  width: 100%;
  margin: 20px auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
}

.amounts-div.step-3 {
  justify-content: center;
}

.amounts {
  width: 130px;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 15px;
  border: 1px solid #007c16;
  border-radius: 4px;
  color: #007c16;
  font-weight: 600;
  cursor: pointer;
}

.amounts.duration {
  width: auto;
  height: auto;
  padding: 10px 14px;
}

.amounts.active {
  background-color: #007c16;
  color: #fff;
}

.amounts h5 {
  position: relative;
  font-size: 22px;
  margin-top: 8px;
}

.amounts .custom-amount {
  font-size: 16px;
}

.amounts h5 span {
  font-size: 14px;
  position: absolute;
  top: 4px;
  left: -10px;
}

.continue-btn {
  width: 250px;
  padding: 12px 16px;
  background-color: #007c16;
  border-radius: 4px;
  border: none;
  outline: none;
  color: #fff;
  font-size: 18px;
  letter-spacing: 0.09em;
}

.checkbox-container {
  width: 100%;
  padding: 5px;
  background: #e1e4f0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s ease;
}

.checkbox-container.checked {
  background-color: #007c16;
}

.checkbox-container label {
  color: #000;
  font-size: 18px;
  letter-spacing: 0.09em;
  padding: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  transition: color 0.3s ease;
}

.checkbox-container.checked label img {
  width: 150px;
  height: auto;
  position: absolute;
  top: -60px;
  left: 0;
}

.checkbox-container.checked label {
  color: #fff;
}

.checkbox-container label span {
  margin-left: 1rem;
}

.checkbox-container input[type="checkbox"] {
  width: 32px;
  height: 32px;
  transition: accent-color 0.3s ease, outline 0.3s ease;
}

.checkbox-container.checked input[type="checkbox"]:checked {
  outline: 2px solid #fff;
  accent-color: #007c16;
  animation: popStars 0.5s ease-in-out;
}

@keyframes popStars {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.new-amount {
  width: 100%;
  text-align: center;
  font-size: 64px;
  font-weight: 600;
  margin-bottom: 1rem;
}

/* indicators */
.indicators-box {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  margin: 15px auto;
}

.indicators-box .indicators {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin: 12px;
  border: 1px solid #6f6f6f;
  cursor: pointer;
}

.indicators-box .indicators.active {
  background-color: #6f6f6f;
}

/* footer */
.embedded-footer-box {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f8f8f8;
  border-top: 1px solid #e4e4e4;
  padding-top: 20px;
}

.secure-donation {
  margin-left: 5px;
  margin-top: 3px;
}

.embedded-footer-box .powered-by {
  font-size: 14px;
}

.embedded-footer-box .powered-by img {
  width: 90px;
  height: auto;
  margin-left: -15px;
}

/* step 4 */
.who-is-giving-today {
  margin: 15px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px;
  text-align: center;
}

.who-is-giving-today h5 {
  color: #6b6b6b;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 7px;
}

.who-is-giving-today p {
  font-style: italic;
  color: #767676;
  font-size: 12px;
}

.embed-organization-logo-div {
  width: 150px;
  height: auto;
  margin: 10px auto;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.campaign-msg.embed {
  margin-top: 0;
  padding: 10px;
}

.embed-organization-logo-div .embed-organization-logo-img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.embed-organization-name-h5 {
  font-size: 13px;
  font-weight: 600;
  width: 100%;
  text-align: center;
}

/* Hide the default radio button icon */
.recurring-radio {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
}

/* Add a custom radio button icon */
.recurring-radio::before {
  content: "";
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 2px solid #007c16;
  margin-right: 8px;
  vertical-align: middle;
  background-color: #fff; /* You can change this to match the background color */
}

/* Add custom styles when the radio button is checked */
.recurring-radio:checked::before {
  background-color: #007c16;
}
