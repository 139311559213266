.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgb(0 0 0 / 50%);
  width: 100%;
  height: 100dvh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.backdrop .donation-modal,
.backdrop .payment-modal {
  width: 700px;
  max-height: 90dvh;
  background-color: #007c16;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 15px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.backdrop .donation-modal {
  background-image: url("../images/bg3.jpg");
}

.backdrop .payment-modal {
  background-image: url("../images/payment.jpg");
}

.backdrop .donation-modal .donate-box,
.backdrop .payment-modal .donate-box {
  width: 65%;
  flex: 1;
  background-color: #fff;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  position: relative;
}

@media (max-width: 767px) {
  .backdrop .donation-modal,
  .backdrop .payment-modal {
    width: 100%;
    min-height: 100dvh;
    background-image: none;
  }

  .backdrop .donation-modal .donate-box,
  .backdrop .payment-modal .donate-box {
    width: 100%;
  }
}

.donate-box::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.donate-box::-webkit-scrollbar-thumb {
  background-color: #e1e1e9;
  border-radius: 3px;
}

.donate-box::-webkit-scrollbar-thumb:hover {
  background-color: #b1b1b9;
}

.donate-box::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.donate-box::-webkit-scrollbar-track:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.cross-icon {
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
  border: none;
  outline: none;
  background-color: transparent;
  font-weight: bold;
}

.donate-box .modal-header {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #e4e4e4;
  margin-bottom: 10px;
  text-align: center;
}

.how-often {
  margin-top: 20px;
  color: #3a3a3a;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0.09em;
}

.often-btns-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.often-btns-group button {
  width: auto;
  border-radius: 24px;
  background-color: transparent;
  border: 1px solid #007c16;
  color: #007c16;
  outline: none;
  padding: 5px 10px;
  margin: 5px;
  letter-spacing: 0.09em;
  font-size: 13px;
}

.often-btns-group button.active {
  background-color: #007c16;
  color: #fff;
}

.how-often-div {
  margin-top: 10px;
}

.radio-col {
  display: flex;
  align-items: center;
}

.number-of-donations-input {
  width: 40px;
  outline: none;
  margin: 5px;
}

.back-and-next-div {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}

.back-step-btn,
.back-step-btn:hover {
  letter-spacing: 0.09em;
  color: #fff !important;
  background-color: #6c757d !important;
  border: 1px solid #6c757d !important;
}

.next-btn {
  background-color: #007c16 !important;
  color: #fff;
  letter-spacing: 0.09em;
}

.card-row {
  width: 100%;
  display: flex;
  margin: 7px 0;
}

.card-row .card-col {
  flex: 1;
  margin-right: 5px;
}

@media (max-width: 450px) {
  .card-row {
    flex-direction: column;
  }

  .card-row .card-col {
    width: 100%;
    margin: 10px 0;
  }
}

.transaction-info-box {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.transaction-info-box .transaction-info {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  margin: 10px 0;
  border-bottom: 1px solid #e4e4e4;
  font-size: 14px;
}

@media (max-width: 450px) {
  .transaction-info-box .transaction-info {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

.close-modal-btn {
  width: 100%;
  background-color: #007c16 !important;
  color: #fff;
}

/* donations tab */
.table-head .table-header-th {
  font-weight: bold;
  color: #3a3a3a;
  letter-spacing: 0.09em;
}

.table-row {
  cursor: pointer;
}

/* per-page-and-pagination */
.transactions-pagination {
  width: 100%;
}

.transactions-pagination .per-page-and-pagination {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 35px;
}

.transactions-pagination .per-page-and-pagination .per-page {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  width: fit-content;
}

.per-page label {
  margin-right: 10px;
}

.page-link {
  background-color: #fff !important;
  color: #007c16 !important;
  margin: 0 3px 0 3px !important;
  border: 1px solid #eaeaea !important;
}

.page-link:hover {
  background-color: #dee2e6 !important;
}

.active .page-link {
  background-color: #007c16 !important;
  color: #fff !important;
  z-index: 1 !important;
  border-color: #007c16 !important;
}

/* qr-link-donations css */
.link-email-form {
  width: 96%;
  max-width: 400px;
  margin: 30px auto;
}

.link-email-div {
  display: flex;
  margin: 10px 0;
}

.link-email-div input {
  border-radius: 0 !important;
}

.link-email-div button {
  background-color: #007c16 !important;
  box-shadow: none;
  border-radius: 0 !important;
  letter-spacing: 0.09em;
}

/* registeration modal */
.backdrop.registeration {
  padding: 10px;
}

@media (max-width: 540px) {
  .backdrop.registeration {
    padding: 0;
  }
}

.registeration-modal {
  width: 70%;
  height: 100%;
  overflow-y: auto;
  background-color: #fff;
  border-radius: 8px;
  padding: 10px 20px;
  scrollbar-width: none;
  -ms-overflow-style: none;
  position: relative;
}

.registeration-modal::-webkit-scrollbar {
  display: none;
}

@media (max-width: 540px) {
  .registeration-modal {
    width: 100%;
    border-radius: 0;
  }
}

@media screen and (min-width: 1024px) and (min-height: 1366px) {
  .registeration-modal {
    height: auto;
  }
}

@media screen and (min-width: 768px) and (min-height: 1024px) {
  .registeration-modal {
    height: auto;
  }
}

.close-registeration-modal {
  position: absolute;
  top: 5px;
  right: 5px;
  border: none;
  outline: none;
  padding: 5px 10px;
  font-weight: 600;
}

.registeration-modal-loading-screen {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgb(255 255 255 / 80%);
}

.registeration-modal-loading-screen p {
  color: #007c16;
  margin-top: 10px;
  letter-spacing: 0.09em;
  font-size: 15px;
}

.register-btn-div.registeration-modal {
  justify-content: flex-end;
  width: 100%;
}
