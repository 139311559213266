.recurring-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  overflow-y: auto;
}

.recurring-item-box {
  width: 300px;
  height: auto;
  box-shadow: 5px 5px 4px #e4e4e4;
  margin: 15px;
  background-color: #fff;
  border-radius: 10px;
}

.recurring-item-box.enabled {
  cursor: pointer;
}

.recurring-item-box.disabled {
  cursor: not-allowed;
}

/* organization box */
.recurring-item-box .recurring-organization-box {
  width: 100%;
  height: auto;
  padding-bottom: 20px;
  padding-top: 7px;
  background-color: rgba(0, 124, 22, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.recurring-organization-box.charities-card {
  height: 120px;
  flex-direction: column;
  position: relative;
  padding: 0;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.tag {
  width: 60px;
  position: absolute;
  top: 10px;
  left: -10px;
  background-color: #007c16;
  color: #fff;
  border-radius: 18px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.recurring-organization-box .recurring-organization-img-div {
  width: 50px;
  height: auto;
}

.recurring-organization-img-div .recurring-organization-img {
  width: 100%;
  height: 100%;
}

.recurring-organization-box .recurring-organization-name {
  font-size: 16px;
  font-weight: 600;
  margin-left: 10px;
}

.recurring-organization-name.charities-name {
  margin-left: 0;
  margin-top: 10px;
  text-align: center;
}

/* campaign image */
.recurring-item-box .recurring-campaign-img-div {
  width: 100px;
  margin-left: auto;
  margin-right: auto;
  height: 100px;
  border-radius: 50%;
  margin-top: -20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f8f8f8;
}

.recurring-item-box .recurring-campaign-img {
  width: 70%;
  height: 70%;
  border-radius: 50%;
}

/* recurring-campaign */
.recurring-campaign {
  width: 100%;
  text-align: center;
  font-weight: bold;
  color: #007c16;
  font-size: 16px;
  margin-top: 3px;
}

/* recurring-date */
.recurring-date {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  margin-top: 15px;
  padding: 0 7px;
}

.recurring-date.billing {
  margin-top: 5px;
}

.recurring-date .recurring-attribute {
  color: #8f8f8f;
}

/* button */
.recurring-details-btn {
  width: 100%;
  background-color: #007c16 !important;
  color: #fff !important;
}

.recurring-details-btn.disbaled {
  pointer-events: none;
  cursor: default;
  opacity: 0.6;
}

/* recurringModal */
.change-amount-btn {
  min-width: 185px !important;
  background-color: #007c16 !important;
  color: #fff !important;
}

.change-amount-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.donation-text.update-amount {
  margin-bottom: 10px !important;
}

.recurring-action-btns {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
}

.recurring-action-btns .recurring-update {
  background-color: #007c16 !important;
  color: #fff !important;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 450px) {
  .recurring-action-btns {
    flex-direction: column;
  }

  .recurring-action-btns button {
    width: 100%;
    margin: 10px 0;
  }
}

.close-ac-div {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
}

.close-ac-div .close-ac-btn {
  background-color: transparent;
  color: #007c16;
  border: none;
  outline: none;
}

@media (max-width: 450px) {
  .close-ac-div {
    flex-direction: column;
  }

  .close-ac-div button {
    width: 100%;
    margin: 10px 0;
  }
}

.receipt-value.closed {
  background-color: #007c16;
  color: #fff;
  padding: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.receipt-other-info.recurring {
  position: relative;
}

.drawer-loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
}

/* notes-div */
.notes-div div {
  background-color: #fafafa;
  padding: 15px 10px 10px 10px;
  max-height: 150px;
  overflow-y: auto;
}

.notes-div div::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.notes-div div::-webkit-scrollbar-thumb {
  background-color: #e1e1e9;
  border-radius: 3px;
}

.notes-div div::-webkit-scrollbar-thumb:hover {
  background-color: #b1b1b9;
}

.notes-div div::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.notes-div div::-webkit-scrollbar-track:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

/* view transactions link */
.view-transactions-link {
  border: none;
  outline: none;
  color: #007c16;
  background-color: transparent;
}

/* transactions popup */
.blurry-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100dvh;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000001;
  backdrop-filter: blur(10px);
}

.blurry-backdrop .popup-modal {
  width: 375px;
  height: fit-content;
  max-height: calc(100dvh - 50px);
  background-color: #fff;
  display: flex;
  flex-direction: column;
}

@media (max-width: 450px) {
  .blurry-backdrop .popup-modal {
    width: 100%;
    min-height: 100dvh;
  }
}

.blurry-backdrop .popup-modal.w-900 {
  width: 900px;
}

@media (max-width: 912px) {
  .blurry-backdrop .popup-modal.w-900 {
    width: 100%;
    min-height: 100dvh;
  }
}

.popup-modal .popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #007c16;
  color: #fff;
}

.popup-header div {
  font-size: 14px;
}

.popup-header .close-icon {
  cursor: pointer;
}

.popup-modal .popup-body {
  padding: 20px;
  overflow-y: auto;
  flex-grow: 1;
}

/* Customizing the scrollbar */
.popup-body::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.popup-body::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.popup-body::-webkit-scrollbar-thumb {
  background-color: #e1e1e9;
  border-radius: 3px;
}

.popup-body::-webkit-scrollbar-thumb:hover {
  background-color: #b1b1b9;
}

.popup-body::-webkit-scrollbar-track:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.transactions-table {
  width: 100%;
  max-height: 500px;
  overflow: auto;
  white-space: nowrap;
  position: relative;
}

.transactions-table::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.transactions-table::-webkit-scrollbar-thumb {
  background-color: #e1e1e9;
  border-radius: 3px;
}

.transactions-table::-webkit-scrollbar-thumb:hover {
  background-color: #b1b1b9;
}

.transactions-table::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.transactions-table::-webkit-scrollbar-track:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.thead {
  position: sticky;
  top: 0;
  left: 0;
  background-color: #fafafa;
}

.thead tr th {
  font-weight: 600;
  font-size: 14px;
  white-space: nowrap;
}
