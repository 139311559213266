/* index.css will mostly but not always contain common styles */
@import "https://fonts.googleapis.com/css2?family=Nunito&display=swap";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Nunito", sans-serif;
}

/* wrapper wrapping every route */
.wrapper {
  width: 100%;
  height: auto;
  overflow-x: hidden;
}

/* main container contains header and a sidebar-and-content-container which contains sidebar and content (that can change) */
.main-container {
  width: 100%;
  height: 100dvh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

@media (max-width: 375px) {
  .main-container.logged-in {
    height: 100dvh;
  }
}

/* header....it contains top-nav */
.custom-header {
  width: 100%;
  height: auto;
  box-shadow: 0 1px 15px rgb(0 0 0 / 4%), 0 1px 6px rgb(0 0 0 / 4%);
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 7px 32px;
}

/* top nav contains toggler appLogo-searchForm custom-menu or registeration links */
.custom-header .top-nav {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0;
  flex-wrap: nowrap;
}

.custom-header .top-nav.wrap {
  flex-wrap: wrap;
}

/* custom header > toggler */
.custom-header .toggler {
  display: none;
}

/* custom header > logo-and-form */
.custom-header .logo-and-form {
  display: flex;
  align-items: center;
}

.custom-header .app-logo {
  width: 175px;
  height: 85px;
  margin-left: -45px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.app-logo img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

/* custom header > search form */
.custom-header .search-form {
  width: 250px;
  background-color: #f8f8f8;
  padding: 0 14px;
  border-radius: 28px;
  display: flex;
}

.search-form.flash {
  animation-name: demo;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

@keyframes demo {
  0% {
    background-color: #f8f8f8;
  }

  50% {
    background-color: #ffff64;
    transform: scale3d(1.2, 1.2, 1);
  }

  100% {
    background-color: #fff;
  }
}

.custom-header .search-form .search-field {
  width: 100%;
  border: none;
  outline: none;
  background: transparent;
  flex: 3.5;
  font-size: 13px;
  border-radius: 28px;
}

.custom-header .search-form .search-btn {
  flex: 0.5;
  border: none;
  outline: none;
  background: transparent;
  color: #afacac;
  padding: 10px 0;
}

/* user action div contains links, change lang btn and user profile */
.user-action-div {
  position: relative;
  display: flex;
  align-items: center;
}

.user-action-div .link-icons-div .link-icons-span {
  margin: 0 10px;
  color: #8f8f8f;
  cursor: pointer;
}

.link-icons-span a {
  color: #8f8f8f;
}

.link-icons-span a.active {
  color: #007c16 !important;
}

.user-action-div .link-icons-div .link-icons-span :hover,
.link-icons-span a:hover {
  color: #007c16;
}

@media (max-width: 540px) {
  .user-action-div {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }
}

.change-lang-btn {
  border: none;
  outline: none;
  background-color: transparent;
  margin: 0 10px;
  color: #007c16;
}

.change-lang-btn.embed {
  margin-top: -10px;
  margin-bottom: 5px;
}

.change-lang-btn.auth {
  text-align: right;
}

/* custom dropdown */
.user-action-div .user-profile {
  min-width: 50px;
  min-height: 50px;
  border-radius: 50%;
  overflow: hidden;
  border: none;
  outline: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #007c16;
  color: #fff;
  font-weight: 600;
  letter-spacing: 0.2em;
  font-size: 15px;
}

.user-action-div .custom-profile-dropdown {
  width: 200px !important;
  position: absolute;
  top: 60px;
  right: -10px;
  padding: 10px 20px;
  background-color: #fff;
  box-sizing: 0 5px 25px rgb(0 0 0 / 10%);
  border-radius: 15px;
  transition: 0.5s;
  border: 1px solid #e4e4e4;
  visibility: hidden;
  opacity: 0;
  height: fit-content !important;
}

@media (max-width: 540px) {
  .user-action-div .custom-profile-dropdown {
    right: 10px;
  }
}

.user-action-div .custom-profile-dropdown.active {
  visibility: visible;
  opacity: 1;
}

.user-action-div .custom-profile-dropdown::before {
  content: "";
  position: absolute;
  top: -5px;
  right: 24px;
  width: 20px;
  height: 20px;
  background-color: #fff;
  transform: rotate(45deg);
  border-top: 1px solid #e4e4e4;
  border-left: 1px solid #e4e4e4;
}

.custom-profile-dropdown h3.menu-name {
  text-align: center;
  width: 100%;
  font-size: 18px;
  padding: 20px 0;
  font-weight: 600;
  line-height: 1.2em;
  color: #007c16;
}

.custom-profile-dropdown .menu-items-div {
  width: 100%;
}

.custom-profile-dropdown .menu-items-div .menu-items {
  padding: 10px 0;
  border-top: 1px solid rgb(0 0 0 / 5%);
  width: 100%;
  cursor: pointer;
  color: #000 !important;
  font-size: 13px;
  text-align: left;
}

.custom-profile-dropdown .menu-items-div .menu-items:hover {
  color: #007c16 !important;
}

.menu-items-link {
  color: #000 !important;
}

.menu-items-link.active {
  color: #007c16 !important;
}

.menu-items-link:hover {
  color: #007c16 !important;
}

.tax-receipt {
  padding: 10px 0;
  border-top: 1px solid rgb(0 0 0 / 5%);
  width: 100%;
  cursor: pointer;
  color: #000 !important;
  font-size: 13px;
  display: flex;
  border: none;
  outline: none;
  background-color: transparent;
  text-align: left;
}

.custom-profile-dropdown .menu-items-div .tax-receipt:hover {
  color: #007c16 !important;
}

.menu-items.logout {
  display: flex;
  border: none;
  outline: none;
  background-color: transparent;
}

.menu-items.logout .logout-text {
  margin-right: 15px;
}

/* registeration div */
.registeration-links {
  color: #3a3a3a;
  font-size: 13px;
  margin-right: 15px;
  font-weight: 600;
  letter-spacing: 0.05em;
}

.registeration-links:hover {
  color: #007c16;
}

/* navigation */
.custom-header .navigation {
  display: flex;
  align-items: center;
}

.custom-header .greater-than {
  font-size: 13px;
  font-weight: 600;
  color: #3a3a3a;
  margin-right: 10px;
}

.navigation-links {
  font-size: 13px;
  font-weight: 600;
  color: #3a3a3a;
  margin-right: 10px;
}

.navigation-links.active,
.navigation-links:hover {
  color: #007c16;
}

/* responsive styles for header */
@media (max-width: 767px) {
  .custom-header {
    height: auto;
    padding: 16px 32px;
  }

  .custom-header .app-logo {
    margin-left: 0;
  }

  .custom-header .toggler {
    display: block;
    border: none;
    outline: none;
    background: transparent;
  }
}

@media (max-width: 450px) {
  .custom-header {
    padding: 8px 16px;
  }

  .custom-header .search-form {
    width: auto;
  }
}

@media (max-width: 300px) {
  .custom-header .search-form {
    width: 120px;
  }
}

/* end of header */

/* this contains a sidebar and middle content */
.dashboard-container {
  width: 100%;
  height: 100%;
  display: flex;
  overflow-x: hidden;
  overflow-y: auto;
}

/* sidebar */
.sidebar-box {
  flex: 0.3;
  height: 100%;
  box-shadow: 0 0 50px #ccc;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
}

.sidebar-box::-webkit-scrollbar {
  display: none;
}

.simplebar-scrollbar::before {
  background-color: #9899ac !important;
}

.sidebar-box .link-div {
  width: 100%;
  color: #3a3a3a;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  letter-spacing: 0.09em;
  cursor: pointer;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  border-bottom: 1px solid #f3f3f3;
  padding: 20px;
}

.sidebar-box .link-div.disabled {
  cursor: not-allowed;
}

.sidebar-box .link-div.active {
  color: #007c16;
  cursor: pointer;
}

.sidebar-box .link-div .link-text {
  margin-top: 5px;
  text-align: center;
}

/* responsive styles for toggling sidebar */
@media (max-width: 767px) {
  .dashboard-container {
    position: relative;
  }

  .dashboard-container .sidebar-box {
    position: absolute;
    top: 0;
    left: -200px;
    width: 25%;
    z-index: 2;
    background-color: #fff;
    transition: 0.5s ease-in-out;
    visibility: hidden;
    opacity: 0;
  }

  .dashboard-container .sidebar-box.toggled {
    left: 0;
    opacity: 1;
    visibility: visible;
  }
}

@media (max-width: 450px) {
  .dashboard-container .sidebar-box.toggled {
    width: 30%;
  }
}

@media (max-width: 360px) {
  .dashboard-container .sidebar-box.toggled {
    width: 35%;
  }
}

@media (max-width: 280px) {
  .dashboard-container .sidebar-box.toggled {
    width: 40%;
  }
}

/* rightside */
.home-content {
  flex: 3.7;
  height: 100%;
  background-color: #f8f8f8;
  overflow-x: hidden;
  overflow-y: auto;
}

@media (max-width: 1024px) {
  .sidebar-box {
    flex: 0.5;
  }

  .home-content {
    flex: 3.5;
  }
}

.info-content-div {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  position: relative;
  overflow-y: auto;
}

@media (max-width: 375px) {
  .info-content-div {
    padding: 0;
  }
}

@media (max-height: 750px) {
  .info-content-div {
    height: auto;
  }
}

@media (max-width: 600px) {
  .info-content-div {
    height: auto;
  }
}

/* user before logged in and there is no search result */
.info-content-div .content-box {
  width: 600px;
  padding: 30px;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 3px 3px 2px #e4e4e4;
}

@media (max-width: 767px) {
  .info-content-div .content-box {
    width: 100%;
  }
}

.content-box .welcome-heading {
  letter-spacing: 0.09em;
}

.content-box .welcome-para {
  font-size: 14px;
  color: #8f8f8f;
  margin-top: 20px;
}

.content-box .features-list {
  margin-top: 20px;
  font-size: 14px;
  color: #8f8f8f;
  list-style: none;
}

.content-box .features-list .features-li {
  margin: 5px 0;
}

.features-li::before {
  content: "\2022";
  color: #007c16;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

.content-box .actions-div {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.content-box .actions-div .search-btn {
  color: #007c16;
  border: 1px solid #007c16;
  background-color: transparent;
}

.content-box .actions-div .account-btn {
  background-color: #007c16;
  margin: 0 5px;
}

@media (max-width: 540px) {
  .content-box .actions-div {
    width: 100%;
    flex-direction: column;
    justify-content: center;
  }

  .content-box .actions-div .left,
  .content-box .actions-div .right {
    width: 100%;
  }

  .content-box .actions-div .search-btn,
  .content-box .actions-div .account-btn {
    width: 100%;
    margin: 7px 0;
  }
}

/* user after logged in and there is no search */
.user-welcome-div {
  width: 100%;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

@media (max-height: 600px) {
  .user-welcome-div {
    margin-top: 50px;
  }
}

.user-welcome-heading {
  margin-top: 10px;
}

.user-welcome-text {
  margin-top: 10px;
}

.lets-explore-btn {
  background-color: #007c16 !important;
}

/* if there is any error during search */
.error-and-content {
  display: flex;
  flex-direction: column;
  padding: 15px;
}

/* cache loading */
.cache-loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100dvh;
  background-color: rgba(255, 255, 255, 0.3);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* suggestions popup */
.email-input-container {
  display: flex;
  align-items: center;
  position: relative;
}

.email-input-container .suggestions-popup {
  position: absolute;
  top: 40px;
  width: 100%;
  max-height: 250px;
  overflow-y: auto;
  border: 1px solid #ccc;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 10000;
}

.email-input-container .suggestions-popup.address {
  max-height: 190px;
}

.suggestions-popup .suggestion-item {
  padding: 8px 16px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.suggestions-popup .suggestion-item:hover {
  background-color: #f0f0f0;
}

.suggestion-item .suggestion-name {
  font-size: 13px;
  margin-top: 3px;
}

/* customizing the scrollbar of popup */
.suggestions-popup::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.suggestions-popup::-webkit-scrollbar-thumb {
  background-color: #e1e1e9;
  border-radius: 3px;
}

.suggestions-popup::-webkit-scrollbar-thumb:hover {
  background-color: #b1b1b9;
}

.suggestions-popup::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.suggestions-popup::-webkit-scrollbar-track:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

/* switch */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  /* margin-bottom: 10px; */
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

.switch input:checked + .slider {
  background-color: #007c16;
}

.switch input:focus + .slider {
  box-shadow: 0 0 1px #007c16;
}

.switch input:checked + .slider:before {
  transform: translateX(26px);
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range,
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected,
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background-color: #007c16 !important;
  color: #fff !important;
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover,
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item:hover {
  background-color: #007c16 !important;
  color: #fff !important;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list {
  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #e1e1e9;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #b1b1b9;
  }

  &::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }

  &::-webkit-scrollbar-track:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
}
