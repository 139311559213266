/* shown on home page (/) */
.my-charities-div {
  padding: 10px;
  color: #007c16;
  position: absolute;
  top: 3px;
  right: 15px;
  cursor: pointer;
}

.cards-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 25px;
}

.custom-card {
  width: calc(70% - 40px);
  margin: 20px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 2px 2px 3px #e4e4e4;
  cursor: pointer;
}

.custom-card .card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(0, 124, 22, 0.2);
  padding: 20px;
}

.custom-card .card-header .card-leftside {
  display: flex;
  align-items: center;
}

.card-leftside .card-logo {
  width: 100px;
  height: auto;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-leftside .card-logo img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.card-leftside h3 {
  font-size: 18px;
  font-weight: 600;
  margin-left: 10px;
}

.custom-card .card-header .follow-btn {
  background-color: #007c16;
  color: #fff;
  position: relative;
}

.btn-loader {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-card .city-and-country {
  margin-top: 10px;
  color: #9b9b9b;
  font-size: 16px;
}

.custom-card .other-info {
  display: flex;
  flex-wrap: wrap;
}

.other-info .info-icon-and-text {
  margin-right: 50px;
}

.info-icon-and-text .info-icon {
  color: #007c16;
}

.info-icon-and-text .info-text {
  color: #9b9b9b;
  margin-left: 10px;
  font-size: 14px;
}

/* responsive styles */
@media (max-width: 912px) {
  .cards-container {
    width: calc(100% - 20px);
    flex-direction: column;
    justify-content: center;
    margin: 25px auto;
  }

  .custom-card {
    width: 100%;
    margin: 20px 0;
  }
}

/* 1024px */
@media (max-width: 1024px) {
  .custom-card .card-header {
    flex-direction: column;
    justify-content: center;
    padding: 10px;
  }

  .follow-btn {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .custom-card .other-info {
    flex-direction: column;
  }
}

/* other queries */
@media (max-width: 767px) {
  .other-info .info-icon-and-text {
    margin-right: 40px;
  }
}

@media (max-width: 540px) {
  .other-info .info-icon-and-text {
    margin-right: 30px;
    margin-top: 10px;
  }
}

@media (max-width: 768px) {
  .other-info .info-icon-and-text {
    margin-right: 20px;
  }
}

@media (max-width: 375px) {
  .other-info .info-icon-and-text {
    margin-right: 10px;
  }
}

/* organization (single-card on a page) */
.cards-container.single-card {
  width: 100%;
  display: flex;
  justify-content: center;
}

.custom-card.single-card {
  cursor: auto;
  padding: 0;
  width: 90%;
}

.card-leftside.single-card .card-logo {
  width: 125px;
}

.card-leftside.single-card .title-and-city-country {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 20px;
}

.card-leftside.single-card .organization-name {
  font-size: 28px;
  font-weight: 600;
  margin-left: 0;
  text-align: center;
}

@media (max-width: 1024px) {
  .card-leftside.single-card {
    flex-direction: column;
    justify-content: center;
  }

  .card-leftside.single-card .title-and-city-country {
    align-items: center;
    margin-left: 0;
    margin-bottom: 10px;
  }

  .card-leftside.single-card .organization-name {
    font-size: 20px;
    margin-bottom: 10px;
  }
}

.city-and-country.single-card {
  margin-top: 0;
  color: #9b9b9b;
  font-size: 20px;
}

.other-info.single-card .info-icon-and-text {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 0;
  padding: 10px;
  border-bottom: 1px solid #e4e4e4;
  border-right: 1px solid #e4e4e4;
}

.custom-card .other-info.single-card {
  width: 100%;
  display: flex;
  margin-top: 10px;
}

.campaigns-or-contact {
  width: 100%;
  display: flex;
  margin-top: -2px;
}

.campaigns-or-contact .selector-btns {
  flex: 1;
  padding: 10px 14px;
  background-color: transparent;
  border: none;
  outline: none;
  font-size: 16px;
  font-weight: 600;
  color: #9b9b9b;
  letter-spacing: 0.09em;
  border: 1px solid #007c16;
  color: #007c16;
}

.selector-btns.active {
  background-color: #007c16;
  color: #fff;
}

@media (max-width: 359px) {
  .campaigns-or-contact {
    flex-direction: column;
  }

  .campaigns-or-contact .selector-btns {
    width: 100%;
  }
}

.campaign-msg {
  width: 100%;
  text-align: center;
  margin-top: 20px;
  font-weight: 600;
  font-size: 16px;
  color: #9b9b9b;
  letter-spacing: 0.09em;
  padding: 20px;
}

.campaign-box {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

@media (max-width: 768px) {
  .campaign-box {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.campaign-box.embed {
  justify-content: center;
}

@media (max-width: 768px) {
  .campaign-box.embed {
    flex-direction: row;
  }
}

.campaign-box .campaign {
  width: 300px;
  height: 200px;
  border-radius: 2px;
  border: 1px solid #eaeaea;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px;
  cursor: pointer;
  position: relative;
}

.campaign .campaign-progress-bar {
  height: 8px;
  width: 100%;
  background-color: #f5f5f5;
  position: absolute;
  bottom: 0;
  left: 0;
}

.campaign-progress-bar .progress-bar {
  height: 100%;
}

.campaign-details-progress-div {
  height: 16px;
  width: 100%;
  background-color: #f5f5f5;
  margin-top: -1px;
}

.campaign-details-progress-div .progress-bar {
  height: 100%;
}

@media (max-width: 768px) {
  .campaign-box .campaign {
    width: 90%;
    margin: 20px 0;
  }
}

.campaign-box.embed .campaign.embed {
  width: 170px;
  height: 130px;
  margin: 15px;
  padding: 15px;
  overflow: hidden;
}

@media (max-width: 450px) {
  .campaign-box.embed .campaign.embed {
    width: 100%;
  }
}

.campaign .campaign-icon {
  width: 100px;
  height: auto;
  margin-bottom: 20px;
}

.campaign.embed .campaign-icon.embed {
  width: 50px;
  margin-bottom: 10px;
}

.campaign-icon .campaign-icon-img {
  width: 100%;
  height: 100%;
}

.campaign .campaign-text {
  font-weight: 600;
  text-align: center;
}

.campaign.embed .campaign-text.embed {
  font-size: 14px;
  text-align: center;
}

/* contact-box */
.contact-box {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px 14px;
  margin-top: 30px;
}

.contact-box.charities-contact-box {
  margin: 5px;
  padding: 5px;
}

.get-in-touch-heading {
  color: #007c16;
  font-size: 16px;
  letter-spacing: 0.09em;
  font-weight: 600;
  margin-bottom: 20px;
}

.contact-info-box {
  font-size: 14px;
}

.contact-info-box h6 {
  font-weight: 600;
}

.contact-info-box .p-row {
  display: flex;
  margin: 5px 0;
}

.p-row-text {
  margin-left: 7px;
}

.p-row-text a,
.p-row-text a:hover {
  color: #007c16;
}

.empty-div {
  margin-right: 16px;
}

/* donation form in charity page */
.donation-box {
  width: 350px;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.donation-box.added-width {
  width: 650px;
}

@media (max-width: 1024px) {
  .custom-quantity-col {
    flex: 0 0 100% !important;
    max-width: 100% !important;
    margin-bottom: 8px !important;
    margin-top: 8px !important;
  }
}

.donation-box .display-minimum-amount {
  min-height: auto;
  padding-top: 0.33em;
  padding-bottom: 0.33em;
  padding-left: 0.75em;
  padding-right: 0.75em;
  border: 1px solid #bdbdbd;
  background: #ededed;
  transition: all 0.2s linear;
  display: flex;
  width: 100%;
  font-size: 1.02rem;
  letter-spacing: 0.09em;
  font-weight: 600;
  line-height: 1.72;
  color: #4f4f4f;
  appearance: none;
  border-radius: 0.25rem;
}

@media (max-width: 1024px) {
  .donation-box {
    width: 100%;
    padding: 10px;
  }

  .donation-box.added-width {
    width: 100%;
  }
}

.donation-text {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 5px;
}

.donation-text.hidden {
  display: hidden;
  opacity: 0;
}

.quantity-donate-btn {
  background-color: #007c16 !important;
  box-shadow: none;
  line-height: 1.77 !important;
  width: 100%;
  height: 39px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  overflow: hidden;
}

@media (max-width: 1024px) {
  .donation-text.hidden {
    display: none;
  }

  .quantity-donate-btn {
    margin-top: 5px;
  }
}

.donation-form {
  display: flex;
  flex-direction: column;
}

.input-and-btn-div {
  display: flex;
}

.minimum-amount-msg {
  font-weight: 600;
  font-size: 12px;
  color: #9b9b9b;
}

.minimum-amount-msg.red {
  color: red;
}

.input-and-btn-div .donate-btn {
  width: fit-content;
  height: 39px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-left: 5px;
  background-color: #007c16 !important;
  overflow: hidden;
  box-shadow: none;
}

@media (max-width: 450px) {
  .input-and-btn-div {
    width: 100%;
    flex-direction: column;
  }

  .input-and-btn-div .donate-btn {
    width: 100%;
    margin-left: 0;
    margin-top: 5px;
    margin-bottom: 10px;
  }
}

.campaign-msg-and-btn-div {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.go-back-to-campaign-selection-btn {
  border: 1px solid #007c16;
  outline: none;
  background-color: transparent;
  padding: 5px 24px;
  color: #007c16;
  margin: 10px auto;
}

.campaign-collection {
  width: 100%;
  display: flex;
  padding: 20px;
}

.campaign-collection .collection-box {
  flex: 1;
  border-right: 1px solid #9b9b9b;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.campaign-collection .collection-box.last {
  border-right: 0;
}

@media (max-width: 540px) {
  .campaign-collection {
    flex-direction: column;
  }

  .campaign-collection .collection-box {
    border-right: 0;
    border-bottom: 1px solid #9b9b9b;
  }

  .campaign-collection .collection-box.last {
    border-bottom: 0;
  }
}

.banner-img-container {
  width: 100%;
  max-width: 500px;
  height: auto;
  min-height: 300px;
  max-height: 500px;
  /* border: 1px solid #e4e4e4; */
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.banner-img-container img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.mdb-cancel-btn {
  background-color: #696a63 !important;
  width: 100%;
  margin-top: 16px;
  box-shadow: none !important;
}

/* Dropdown container */
.MuiAutocomplete-listbox {
  max-height: 250px !important;
  overflow-y: auto;
}

/* Custom scrollbar styles */
.MuiAutocomplete-listbox::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.MuiAutocomplete-listbox::-webkit-scrollbar-thumb {
  background-color: #e1e1e9;
  border-radius: 3px;
}

.MuiAutocomplete-listbox::-webkit-scrollbar-thumb:hover {
  background-color: #b1b1b9;
}

.MuiAutocomplete-listbox::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.MuiAutocomplete-listbox::-webkit-scrollbar-track:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.list-div {
  padding: 12px 8px;
  margin: 4px 0;
  font-size: 16px;
  color: #333;
}

.list-div:hover {
  background-color: rgba(0, 124, 22, 0.2);
}

.list-div[aria-selected="true"] {
  background-color: #007c16;
  color: #fff;
}
